/* componente para incluir uma imagem, um titulo, um subtitulo, 3 spans, outro subtitulo, um texto e um botão */
import React from "react";

interface BigcardProps {
  src: string;
  alt: string;
  title1: string;
  title2: string;
  title3: string;
  text1: string[];
  text2: string[];
  text3: string[];
  longText: string;
  button: string;
}

const Bigcard: React.FC<BigcardProps> = ({
  src,
  alt,
  title1,
  title2,
  title3,
  text1,
  text2,
  text3,
  longText,
  button,
}) => {
  return (
    <div className="app-bigcard-wrapper">
      <img className="bigcard-image" src={src} alt={alt} />
      <div className="bigcard-text">
        <h2>{title1}</h2>
        <h3>{title2}</h3>
        <div className="highlight-box">
          <div className="highlight-span">
            <span>{text1[0]}</span>
            <span>{text1[1]}</span>
          </div>
          <div className="highlight-span">
            <span>{text2[0]}</span>
            <span>{text2[1]}</span>
          </div>
          <div className="highlight-span">
            <span>{text3[0]}</span>
            <span>{text3[1]}</span>
          </div>
        </div>
        <strong>{title3}</strong>
        <p>{longText}</p>
        <button className="green-app-btn btn-over-banner desk-layout">{button}</button>
      </div>
    </div>
  );
};

export default Bigcard;
