import React from "react";
import Logo from "./Logo.tsx";
import Menu from "./Menu.tsx";

type HeaderProps = {
  Logo: React.ComponentType;
  Menu: React.ComponentType;
};

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <header className="wrapper-header">
      <div className="app-header">
        <div className="app-logo">
          <Logo />
        </div>
        <Menu />
      </div>
    </header>
  );
};

export default Header;
