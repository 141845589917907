/* componente tsx de menu */
import React from "react";

/* type MenuProps = {
  children: React.ReactNode;
}; */

const Menu: React.FC = () => {
  return (
    <nav className="app-menu">
      <ul>
        <li>
          <a href="#home">HOME</a>
        </li>
        <li>
          <a href="#servicos">SERVIÇOS</a>
        </li>
        <li>
          <a href="#clientes">CLIENTES</a>
        </li>
        <li className="whats-app-btn">
          <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5551994814615">
            <span>WhatsApp</span> <img src="/images/whats-icon.png" alt="" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
