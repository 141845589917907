/* componente para receber uma imagem tela toda*/
import React from "react";

type ImageFullProps = {
  src: string;
  alt: string;
  title?: string[];
  text?: string[]; // Update the type of the 'text' property to specify the type of array elements
  button?: string;
};

const ImageFull: React.FC<ImageFullProps> = ({
  src,
  alt,
  title,
  text,
  button,
}) => {
  return (
    <div className="hero-box">
      <div className="info-box">
        <div className="title-box">
          {title?.map((item, index) => (
            <h2 key={index}>{item}</h2>
          ))}
        </div>

        <div className="text-box">
          {text?.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>

        {button ? (
          <a
            href="https://api.whatsapp.com/send?1=pt_BR&phone=5551994814615"
            className="btn-over-banner"
          >
            {button}
          </a>
        ) : null}
      </div>
      <div className="image-wrapper">
        <img className="hero-image" src={src} alt={alt} />
      </div>
      <div className="bg-color-box"></div>
    </div>
  );
};

export default ImageFull;
