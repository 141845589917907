/* componente que vai carregar slider feito com slick */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselCard from "./CarouselCard.tsx";

const SliderCarousel: React.FC = () => {
  // Componentes de seta personalizados
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img
        className={className}
        src="/images/arrow-green.png"
        alt="Next"
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img
        className={className}
        src="/images/arrow-green.png"
        alt="Previous"
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow className="next-arrow" />,
    prevArrow: <PrevArrow className="prev-arrow" />,
  };

  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    /* nextArrow: <NextArrow className="next-arrow" />,
    prevArrow: <PrevArrow className="prev-arrow" />, */
  };

  return (
    <>
      <Slider className="desk-layout slick-custom-1" {...settings}>
        <CarouselCard image="/images/banner-0.png" />
        <CarouselCard image="/images/banner-1.png" />
        <CarouselCard image="/images/banner-2.png" />
        <CarouselCard image="/images/banner-3.png" />
        <CarouselCard image="/images/banner-4.png" />
        <CarouselCard image="/images/banner-5.png" />
        <CarouselCard image="/images/banner-6.png" />
        <CarouselCard image="/images/banner-7.png" />
        <CarouselCard image="/images/banner-8.png" />
        <CarouselCard image="/images/banner-9.png" />
        <CarouselCard image="/images/banner-11.png" />
        <CarouselCard image="/images/banner-12.png" />
      </Slider>

      <Slider className="mobile-layout slick-custom-1" {...settings2}>
        <CarouselCard image="/images/banner-0.png" />
        <CarouselCard image="/images/banner-1.png" />
        <CarouselCard image="/images/banner-2.png" />
        <CarouselCard image="/images/banner-3.png" />
        <CarouselCard image="/images/banner-4.png" />
        <CarouselCard image="/images/banner-5.png" />
        <CarouselCard image="/images/banner-6.png" />
        <CarouselCard image="/images/banner-7.png" />
        <CarouselCard image="/images/banner-8.png" />
        <CarouselCard image="/images/banner-9.png" />
        <CarouselCard image="/images/banner-11.png" />
        <CarouselCard image="/images/banner-12.png" />
        </Slider>
    </>
  );
};

export default SliderCarousel;
