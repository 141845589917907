/* componente para receber um item de um carrossel slider */
import React from 'react';

interface CarouselCardProps {
  image: string;
  alt?: string;
}

const CarouselCard: React.FC<CarouselCardProps> = ({ alt = 'image', image }) => {
  return (
    <div className="carousel-card">
      <img src={image} alt={alt} />
    </div>
  );
};

export default CarouselCard;
