/* componente para receber um bloco com 3 colunas, na primeira coluna havéra apenas um logo, na segunda um menu e na terceira mais um menu social*/
import React from "react";

const CollumBlocks: React.FC = () => {
  return (
    <>
      <div className="wrapper-footer desk-layout">
        <div className="app-footer">
          <div className="app-logo-footer collum-footer">
            <img src="/images/logo-light.png" alt="" />
          </div>
          <div className="collum-footer">
            <ul className="menu-info">
              <li>
                <i>
                  <img src="/images/icon-4.png" alt="" />
                </i>
                <div>
                  <p>Rua: Pelotas, 1122</p>
                  <span>Esteio/ RS</span>
                </div>
              </li>
              <li>
                <i>
                  <img src="/images/icon-2.png" alt="" />
                </i>
                <div>
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send?1=pt_BR&phone=5551994814615"
                    rel="noreferrer"
                  >
                    <p className="number">51 994.814.615</p>
                  </a>
                </div>
              </li>
              <li>
                <i>
                  <img src="/images/icon-1.png" alt="" />
                </i>
                <div>
                  <p className="email">comercial@elonghienergia.com.br</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="collum-footer">
            <ul className="menu-social">
              <li>
                <span>
                  Certificação:
                  <br />
                  IPPP-54232/RS
                </span>
              </li>
              <li>
                <span>
                  CNPJ:
                  <br />
                  50.136.346/0001-90
                </span>
              </li>
              <li>
                <div className="social-icons">
                  <p>Siga nossas redes sociais:</p>
                  <div>
                    <a href="https://www.facebook.com/ELONGHIenergia/">
                      <img src="/images/social-face.png" alt="" />
                    </a>
                    <a href="https://www.instagram.com/elonghienergia/">
                      <img src="/images/social-insta.png" alt="" />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="wrapper-footer mobile-layout">
        <div className="app-footer">
          <div className="collum-footer">
            <div className="info-mob-footer">
              <div className="app-logo-footer">
                <img src="/images/logo-light.png" alt="" />
              </div>

              <ul className="menu-info">
                <li>
                  <i>
                    <img src="/images/icon-4.png" alt="" />
                  </i>
                  <div>
                    <p>Rua: Pelotas, 1122</p>
                    <span>Esteio/ RS</span>
                  </div>
                </li>
                <li>
                  <i>
                    <img src="/images/icon-2.png" alt="" />
                  </i>
                  <div>
                    <a
                      target="_blank"
                      href="https://api.whatsapp.com/send?1=pt_BR&phone=5551994814615"
                      rel="noreferrer"
                    >
                      <p className="number">51 994.814.615</p>
                    </a>
                  </div>
                </li>
                <li>
                  <i>
                    <img src="/images/icon-1.png" alt="" />
                  </i>
                  <div>
                    <p className="email">comercial@elonghienergia.com.br</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <img className="app-line-footer" src="/images/line_1.png" alt="" />
          <div className="collum-footer">
            <ul className="menu-social">
              <li>
                <div className="social-icons">
                  <p>Siga nossas redes sociais:</p>
                  <div>
                    <a href="https://www.facebook.com/ELONGHIenergia/">
                      <img src="/images/social-face.png" alt="" />
                    </a>
                    <a href="https://www.instagram.com/elonghienergia/">
                      <img src="/images/social-insta.png" alt="" />
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <span>CNPJ: 50.136.346/0001-90</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollumBlocks;
