/* componente react para incluir o conteúdo  */
import React from "react";

import ImageFull from "./ImageFull.tsx";
import SliderCarousel from "./SliderCarousel.tsx";
import SliderCarousel2 from "./SliderCarousel2.tsx";
import Bigcard from "./BigCard.tsx";

const Content: React.FC = () => {
  return (
    <main className="app-content">
      <section id="home">
        <ImageFull
          src="/images/hero_imagem_main.png"
          alt="Banner"
          title={[
            "De energia a gente entende!",
            "Projeto, instalação e manutenção",
          ]}
          text={[
            "Usina Solar Fotovoltaica",
            "Subestações de Condomínios, Comércios e Industrias",
            "Carregadores Veiculares",
            "Elétrica Industrial",
          ]}
          button="solicite um orçamento!"
        />
      </section>
      <section id="servicos" className="app-carousel-section">
        <h2 className="desk-layout">
          Conheça alguns dos <strong>nossos projetos já realizados</strong>
        </h2>
        <h2 className="mobile-layout">
          Conheça alguns dos nossos <br></br> projetos já realizados
        </h2>

        <SliderCarousel />
      </section>
      <section className="app-bigcard">
        <Bigcard
          src={"/images/imagem_instalacao_1.png"}
          alt={"imagem_instalacao 1"}
          title1={"Conheça um pouco da nossa história!"}
          title2={"Elonghi está presente no mercado desde 2013"}
          title3={"Estamos presentes no mercado deste 2013."}
          text1={["+ 200", "Clientes"]}
          text2={["+ 10 anos", "de Experiência"]}
          text3={["+ 3.500", "KWp"]}
          longText={
            "Mantendo o compromisso com a nossa essência de realizar negócios de forma honesta e sustentável, sempre buscando soluções eficazes aos clientes e parceiros de forma personalizada. Temos como Missão, garantir a satisfação do cliente com soluções criativas, ideias inovadoras e serviços de qualidade com máxima eficiência que superem suas expectativas."
          }
          button={"solicite um orçamento"}
        />
      </section>
      <section
        className="app-slider-2"
        style={{
          background: `url('/images/bg-slider-2.png') no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <SliderCarousel2 />
      </section>
      <section id="clientes" className="app-clients">
        <h2 className="app-clients-title">
          Conheça alguns de nossos <strong>clientes e parceiros</strong>
        </h2>
        <img className="desk-layout" src="/images/all-clients.png" alt="" />
        <img
          className="mobile-layout"
          src="/images/all-clients-mobile.png"
          alt=""
        />
      </section>
      <section className="app-videos-link desk-layout">
        <div className="box-video-links">
          <div className="half-box">
            <h2 className="app-clients-title">
              <strong>Assista alguns vídeos</strong> de<br></br> projetos que já
              foram{" "}
              <strong>
                realizados<br></br> pela equipe Elonghi Energia
              </strong>
            </h2>
            <a
              href="https://api.whatsapp.com/send?1=pt_BR&phone=5551994814615"
              className="green-app-btn btn-over-banner"
            >
              solicite um orçamento
            </a>
          </div>
          <div className="half-box">
            <a href="https://www.instagram.com/elonghienergia/reels/">
              <img
                className="image1"
                src="/images/smartphone-1.png"
                alt="image1"
              />
              <img
                className="image2"
                src="/images/smartphone-2.png"
                alt="image2"
              />
            </a>
          </div>
        </div>
      </section>
      <section className="app-videos-link mobile-layout">
        <div className="box-video-links">
          <div className="mobile-layout">
            <h2 className="app-clients-title">
              <strong>Assista alguns vídeos</strong> de<br></br> projetos que já
              foram{" "}
              <strong>
                realizados<br></br> pela equipe Elonghi Energia
              </strong>
            </h2>

            <a
              className="img-wrapper"
              href="https://www.instagram.com/elonghienergia/reels/"
            >
              <img
                className="image1-mob"
                src="/images/phones-image2-mob.png"
                alt="image1"
              />
            </a>
            <a
              className="green-app-btn btn-over-banner"
              href="https://www.instagram.com/elonghienergia/reels/"
            >
              assistir vídeos
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Content;
