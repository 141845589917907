/* componente que vai carregar slider feito com slick */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselCardImgText from "./CarouselCardImgText.tsx";

const SliderCarousel: React.FC = () => {
  // Componentes de seta personalizados
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img
        className={className}
        src="/images/white-green-arrow.png"
        alt="Next"
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img
        className={className}
        src="/images/white-green-arrow.png"
        alt="Previous"
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow className="next-arrow" />,
    prevArrow: <PrevArrow className="prev-arrow" />,
  };

  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    /* nextArrow: <NextArrow className="next-arrow" />,
    prevArrow: <PrevArrow className="prev-arrow" />, */
  };

  return (
    <div className="wrapper-slider-2">
      <div className="box-slider-2">
        <h2>
          Nossos projetos são exclusivos e personalizados de acordo<br></br> com a
          necessidade de cada cliente
        </h2>
        <Slider className="desk-layout slick-custom-2" {...settings}>
          <CarouselCardImgText
            image="/images/circle-img-1.png"
            title={"Usina Solar"}
            text={
              "Oferecemos assessoria completa para a aquisição da  sua usina fotovoltaica. Do início ao fim, desenvolvemos todo o processo, desde a compra dos equipamentos, a conexão junto à concessionaria, instalação do sistema, pós venda acompanhando geração e manutenções futuras."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-2.png"
            title={"Carregadores Veiculares"}
            text={
              "Venda e instalação de carregadores de veículos elétricos, projetos comerciais e residenciais. Rápidos, eficientes e amigáveis ao meio ambiente, os carregadores tornam a mobilidade mais econômica a médio prazo. Promovendo, também, uma mudança positiva para o planeta. Conte conosco para projetar e instalar seu carregador veicular."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-3.png"
            title={"Elétrica Industrial"}
            text={
              "Somos uma empresa altamente capacitada em projetar e executar serviços de qualquer natureza, para isto contamos com a nossa experiência de mais de 16 anos na área de eletricidade. Fornecemos soluções e serviços para a indústria em geral, desde novos projetos de instalações à manutenção preventiva e corretiva de maquinário."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-4.png"
            title={"Soluções em Geradores"}
            text={
              "Buscando atender as demandas de clientes dos mais variados segmentos, a Elonghi Energia em parceria com a STEMAC oferece um catálogo de produtos que abrange diferentes níveis de necessidade. Todos os equipamentos são produzidos sob os mais rígidos padrões de qualidade, garantindo plena funcionalidade e elevada durabilidade."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-5.png"
            title={"Subestações de Energia"}
            text={
              "Instalação e Manutenção; Projeto e Comissionamento; Termografia; Medição de resistência de isolamento; Análise e laudo de óleo isolante; Revisão do sistema de aterramento; Reaperto geral das conexões elétricas; Limpeza e pintura da sala da subestação."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-6.png"
            title={"Parcerias & Conexões"}
            text={
              "Realizamos parcerias e consultoria para diversos escritórios de arquitetura e engenharia unindo conhecimentos e sempre visando entregar as melhores soluções a nossos clientes, com foco na segurança, prazos e qualidade nos serviços realizados, entre em contato conosco e seja mais um parceiro da Elonghi Energia."
            }
          />
        </Slider>
        <Slider className="mobile-layout slick-custom-2" {...settings2}>
          <CarouselCardImgText
            image="/images/circle-img-1.png"
            title={"Usina Solar"}
            text={
              "Oferecemos assessoria completa para a aquisição da  sua usina fotovoltaica. Do início ao fim, desenvolvemos todo o processo, desde a compra dos equipamentos, a conexão junto à concessionaria, instalação do sistema, pós venda acompanhando geração e manutenções futuras."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-2.png"
            title={"Carregadores Veiculares"}
            text={
              "Venda e instalação de carregadores de veículos elétricos, projetos comerciais e residenciais. Rápidos, eficientes e amigáveis ao meio ambiente, os carregadores tornam a mobilidade mais econômica a médio prazo. Promovendo, também, uma mudança positiva para o planeta. Conte conosco para projetar e instalar seu carregador veicular."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-3.png"
            title={"Elétrica Industrial"}
            text={
              "Somos uma empresa altamente capacitada em projetar e executar serviços de qualquer natureza, para isto contamos com a nossa experiência de mais de 16 anos na área de eletricidade. Fornecemos soluções e serviços para a indústria em geral, desde novos projetos de instalações à manutenção preventiva e corretiva de maquinário."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-4.png"
            title={"Soluções em Geradores"}
            text={
              "Buscando atender as demandas de clientes dos mais variados segmentos, a Elonghi Energia em parceria com a STEMAC oferece um catálogo de produtos que abrange diferentes níveis de necessidade. Todos os equipamentos são produzidos sob os mais rígidos padrões de qualidade, garantindo plena funcionalidade e elevada durabilidade."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-5.png"
            title={"Subestações de Energia"}
            text={
              "Instalação e Manutenção; Projeto e Comissionamento; Termografia; Medição de resistência de isolamento; Análise e laudo de óleo isolante; Revisão do sistema de aterramento; Reaperto geral das conexões elétricas; Limpeza e pintura da sala da subestação."
            }
          />
          <CarouselCardImgText
            image="/images/circle-img-6.png"
            title={"Parcerias & Conexões"}
            text={
              "Realizamos parcerias e consultoria para diversos escritórios de arquitetura e engenharia unindo conhecimentos e sempre visando entregar as melhores soluções a nossos clientes, com foco na segurança, prazos e qualidade nos serviços realizados, entre em contato conosco e seja mais um parceiro da Elonghi Energia."
            }
          />
        </Slider>
        <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5551994814615" className="green-app-btn btn-over-banner">solicite um orçamento</a>
      </div>

    </div>
  );
};

export default SliderCarousel;
