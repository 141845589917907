import "./App.scss";
import Header from "./components/HeaderDef.tsx";
import Content from "./components/Content.tsx";
import Footer from "./components/Footer.tsx";
import CollumBlocks from "./components/CollumBlocks.tsx";

function App() {
  return (
    <div className="app">
      {/* <div className="under-construction"><h2>PÁGINA EM CONSTRUÇÃO</h2></div> */}
      <Header />
      <Content />
      <Footer>
        <CollumBlocks />
      </Footer>
    </div>
  );
}

export default App;
