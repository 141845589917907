/* componente react para incluir o footer  */
import React from "react";

type FooterProps = {
  children: React.ReactNode;
};

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <footer className="app-footer">
      {props.children}
    </footer>
  );
};

export default Footer;
