/* componente item de carousel contendo uma imagem, um titúlo, e um texto */
import React from "react";

const CarouselCardImgText: React.FC<{
  image: string;
  title: string;
  text: string;
}> = ({ image, title, text }) => {
  return (
    <div className="carousel-card-img-text">
      <div className="box-carousel">
        <img src={image} alt={title} />
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default CarouselCardImgText;
